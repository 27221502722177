@import '../../styles/_variables.scss';

.filters {
    display: flex;
    padding: 20px 26px 26px;
    background-color: #3b3b3b;
    font-family: $header-font;

    h3 {
        margin: 0 0 15px;
    }

    .RegionPicker {
        width: 177px;
        margin-right: 22px;
    }

    .CinemaPicker {
        width: 378px;
    }
}

.title-and-sort {
    display: flex;
    align-items: center;
    margin: 50px 0;

    h1 {
        margin: 0;
        font-size: 30px;
    }

    .SortPicker {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-family: $header-font;

        .icon-sort {
            width: 21px;
            height: 18px;
            margin-right: 8px;
            cursor: pointer;
            transition: all .2s ease-in-out;

            &.desc {
                transform: rotate(180deg);
            }
        }

        .react-select-container {
            width: 228px;
        }
    }
}

.MovieList {
    display: flex;
    flex-wrap: wrap;
}

.MovieListItem {
    width: calc(20% - 8px);
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-bottom: 8px;
    background-image: linear-gradient(to top, #d4d4d4, #eee);
    cursor: pointer;

    &:first-of-type,
    &:nth-of-type(6) {
        margin-left: 0;
    }

    &:hover > .Poster,
    &:hover > .poster-container {
        box-shadow: 0 8px 15px -7px black;

        img {
            transform: scale(1.05);
        }
    }

    .poster-container {
        overflow: hidden;
    }

    .Poster {
        width: 100%;
        overflow: hidden;

        img {
            transition: all .2s ease-in-out;
            margin-bottom: -6px;
        }
    }

    .movie-info {
        margin: 0 16px;
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }

    h2 {
        color: black;
        margin: 12px 16px 8px;
        font-size: 22px;
        font-weight: 500;
        overflow: hidden;
    }

    .Button {
        margin-top: auto;
        margin-bottom: 16px;
        margin: auto 16px 16px;
        color: white;
        background-image: linear-gradient(to top, #950200, #9e0200);
    }
}

@media (min-width: 1100px) {
    .Poster {
        height: 335px;
    }
}
