﻿@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?hvoyyo');
    src: url('../assets/fonts/icomoon.eot?hvoyyo#iefix') format('embedded-opentype'), 
         url('../assets/fonts/icomoon.ttf?hvoyyo') format('truetype'), 
         url('../assets/fonts/icomoon.woff?hvoyyo') format('woff'),
         url('../assets/fonts/icomoon.svg?hvoyyo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
    content: "\e908";
}
