.overlay {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    color: white;
    font-size: 20px;
    background-color: rgba(161, 161, 161, 0.95);

    .overlay-content {
        margin: auto;
        text-align: center;
        padding: 20px;
        margin-top: 150px;
        background-color: rgba(0,0,0,0.6);
        position: relative;
    }
}