﻿@font-face {
    font-family: 'Oswald';
    src: url('/assets/fonts/Oswald/Oswald-Regular.ttf') format('truetype');
    font-weight: normal; //400
}

@font-face {
    font-family: 'Oswald';
    src: url('/assets/fonts/Oswald/Oswald-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Oswald';
    src: url('/assets/fonts/Oswald/Oswald-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Oswald';
    src: url('/assets/fonts/Oswald/Oswald-Bold.ttf') format('truetype');
    font-weight: bold; //700
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
}