﻿@import '_variables.scss';

.Button {
    font-family: $header-font;
    min-width: 100px;
    height: 31px;
    padding-bottom: 2px;
    background-color: buttonface;
    background-image: linear-gradient(rgba(156, 156, 156, 0.1), rgba(193, 197, 193, 0.4));
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: none;
    text-align: center;
    border-radius: 2px;
    font-weight: bold;

    span {
        margin-right: 10px;
    }

    &:hover {
        box-shadow: 0 8px 15px -7px black;
    }

    &.disabled {
        color: gray;
        background: #cfcfcf;
        font-weight: normal;
        pointer-events: none;
        cursor: default;

        &:hover {
            box-shadow: none;
            transition: none;
            font-weight: normal;
        }
    }
}

.Checkbox {
    @extend %controller;
    display: flex;
    align-items: center;

    input + span {
        border-radius: 2px;

        &::after {
            background: url(../assets/images/icons/checkmark.svg) no-repeat center;
            background-size: contain;
            width: 100%;
            height: 100%;
        }
    }
}

// Custom checkbox
%controller {
    position: relative;
    cursor: pointer;

    &::selection {
        background: transparent;
    }
    // The checkbox box
    input + span {
        content: "";
        display: inline-block;
        margin: 0 10px 0 0;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        background: transparent;
        border: 1px solid #757575;
        height: 20px;
        width: 20px;
        padding: 4px;

        &:focus {
            outline: none;
        }

        &::after {
            content: "";
            display: block;
            transform: scale(0);
            transition: transform .2s;
        }
    }

    @media screen and (min-width: 768px) {
        &:hover input + span {
            box-shadow: 0 2px 4px rgba(#000, .15);
        }
    }

    input:active + span {
        box-shadow: 0 4px 8px rgba(#000, .15);
    }

    input:focus + span {
        box-shadow: 0 0 0 3px lightblue;
    }

    input:checked + span::after {
        transform: scale(1);
    }

    input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
    }
}
