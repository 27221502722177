@import-normalize;
@import '_variables.scss';
@import '_fonts.scss';
@import '_inputs.scss';
@import '_icomoon.scss';

* {
    box-sizing: border-box;
}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    /*background-color: transparent;*/
    background: #262626;
    color: white;
    font-size: 16px;
    font-family: $body-font, sans-serif, 'Trebuchet MS', Verdana;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
}

h1, h2, h3, h4 {
    font-family: $header-font;
    font-weight: 600;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

.App {
    max-width: 1170px;
    margin: auto;
}

img {
    width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}


.fade-in {
    visibility: visible;
    opacity: 1;
    transition: all .5s linear;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-out;
    cursor: default;
}
